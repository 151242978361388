@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

@layer base {
  @font-face {
    font-family: 'proxima';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: font-url('fonts/Proxima/ProximaNovaA-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'proxima';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: font-url('fonts/Proxima/ProximaNova-Bold.ttf') format('truetype');
  }
}
